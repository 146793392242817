<template>
  <div>
    <v-card>
      <v-card-title> Relatório de Eventos </v-card-title>
      <!-- FORM -->
      <v-card-text>
        <v-card outlined>
          <v-card-text>
            <v-row>
              <v-col>
                <v-autocomplete
                  :items="this.$store.getters.getCamerasSelect"
                  label="Câmera"
                  v-model="relatorio.cameraId"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Data Inicial"
                  v-model="relatorio.start"
                  type="datetime-local"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Data Final"
                  v-model="relatorio.end"
                  type="datetime-local"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="space-around">
              <v-col cols="auto">
                <v-checkbox
                  v-model="relatorio.withImage"
                  label="Somente com Imagens"
                ></v-checkbox>
              </v-col>
              <v-col cols="auto">
                <v-checkbox
                  v-model="relatorio.withTrat"
                  label="Somente com Tratamento"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                color="success"
                text
                @click="loadPDF"
                v-show="$store.getters.getReport.length"
              >
                Gerar PDF
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                text
                @click="loadTratamentos"
                v-show="$store.getters.getReport.length"
              >
                Buscar Tratamentos
              </v-btn>
              <v-btn color="success" text @click="load"> Buscar </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <!-- TABELA -->
    <v-card class="mt-2" outlined>
      <v-row>
        <v-col>
          <v-spacer></v-spacer>
          <v-text-field
            label="Pesquisar"
            hint="Pesquise pelo evento, tratamento, usuario"
            @input="search"
            clearable
            prepend-inner-icon="mdi-magnify"
            class="mx-2"
          />
        </v-col>
      </v-row>
      <v-simple-table dark>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <!-- <th class="text-left">Nome Cliente</th> -->
              <!-- <th class="text-left">Nome Câmera</th> -->
              <th class="text-left">Evento</th>
              <th class="text-left">Zona</th>
              <th class="text-left">Data Evento</th>
              <th class="text-left">Data Sincronismo</th>
              <th class="text-left">Considerado</th>
              <th class="text-left">Tratamento</th>
              <th v-show="showEventsSoftware" class="text-left">
                Tratamento Software
              </th>
              <th class="text-left">Data Tratamento</th>
              <th class="text-left">Tratado por</th>
              <th class="text-left">Imagem</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in $store.getters.getReportFilter"
              :key="index"
            >
              <td>{{ item.id }}</td>
              <!-- <td>{{ item.clientId }} - {{ item.clientName }}</td>
              <td>{{ item.cameraId }} - {{ item.cameraName }}</td> -->
              <td>
                <!-- {{ item.evtName || `Não Cadastrado ${item.code}` }}  -->
                <ConnORError :code="item.code" :detections="item.detections" :text="item.evtName || `Não Cadastrado ${item.code}` " />
              </td>
              <td>{{ item.zone }}</td>
              <td>{{ new Date(item.createdAt).toLocaleString() }}</td>
              <td>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      {{
                        item.syncAt
                          ? new Date(item.syncAt).toLocaleString()
                          : "Não Sincronizado"
                      }}
                    </span>
                  </template>
                  <SyncPayload v-model="item.syncPayload" />
                </v-tooltip>
              </td>
              <td>
                {{
                  item.tratamentoReal
                    ? item.tratamentoReal
                      ? "REAL"
                      : "FALSO"
                    : ""
                }}
              </td>
              <td>{{ item.tratamento }}</td>
              <td v-if="showEventsSoftware && item.softwarePayload">
                <SoftwareTratamento v-model="item.softwarePayload" />
              </td>
              <td>
                {{
                  item.tratamentoCreatedAt
                    ? new Date(item.tratamentoCreatedAt).toLocaleString()
                    : ""
                }}
              </td>
              <td>
                {{ item.userName ? item.userName : item.monitoramentoName }}
              </td>
              <td>
                <v-img
                  @click="showImage(item)"
                  max-height="150"
                  max-width="250"
                  :src="item.snapshot + $store.getters.getImgTokenQuery"
                ></v-img>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-toolbar dark dense color="primary">
        <v-toolbar-title>Imagem do evento com as detecções</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card>
        <Atendimento
          @next="nextEvent($event)"
          @previous="previousEvent($event)"
        />
      </v-card>
    </v-dialog>
    <DialogLoading
      v-model="$store.getters.getShowDialog"
      :title="$store.getters.getTitleDialog"
      :message="$store.getters.getMessageDialog"
      :progress="$store.getters.getValueDialog"
    />
  </div>
</template>

<script>
import Atendimento from "../components/Eventos/Atendimento.vue";
import SyncPayload from "../components/Relatorio/SyncPayload.vue";
import ConnORError from "../components/Relatorio/ConnORError.vue";
import DialogLoading from "../components/Dialogs/DialogLoading.vue";
import SoftwareTratamento from "../components/Relatorio/SoftwareTratamento.vue";
import PDF from "@/Utils/PDF";
export default {
  name: "RelatorioEventos",
  components: { Atendimento, SyncPayload, SoftwareTratamento, DialogLoading, ConnORError },
  data: () => {
    return {
      tomcatPort: 8080,
      showForm: true,
      dialog: false,
      loading: true,
      drawed: false,
      showEventsSoftware: false,
      relatorio: {
        cameraId: "",
        start: `${new Date().toISOString().substring(0, 10) + "T00:00"}`,
        end: `${new Date().toISOString().substring(0, 10) + "T23:59"}`,
        orderBy: "DESC",
        withTrat: false,
        withImage: false,
      },
    };
  },
  methods: {
    get() {
      this.$store.dispatch("loadCameras");
    },
    load() {
      this.$store.dispatch("loadReportEventos", this.relatorio);
      this.$store.dispatch("setLastReport", this.relatorio);
    },
    loadTratamentos() {
      this.showEventsSoftware = true;
      this.$store.dispatch("loadReportEventosTratamentos", this.relatorio);
    },
    loadPDF() {
      // let filename = `${this.$store.getters.getCameraOnlyNameById(this.relatorio.cameraId)}`;
      // let url = `relatorios${Url.genQueryFromObj({...this.relatorio, pdf:true})}`;
      // this.$http(url, { responseType: "blob" }).then((resp) => {
      //   if (resp.status == 200) {
      //     filename += `.${
      //       resp.data.type.split("/")[1]
      //     }`;
      //     FileUtil.downloadFile(resp.data, filename);
      //   }
      // });
      let filename = `${this.$store.getters.getCameraOnlyNameById(
        this.relatorio.cameraId
      )}`;
      this.$store.dispatch("setTitleDialog", "Gerando o Relátorio");
      this.$store.dispatch("setShowDialog", true);
      PDF.eventosCamera(
        this.$store.getters.getReportFilter,
        "Relatorios de eventos",
        true,
        null,
        filename
      )
        .then(() => {
          this.$store.dispatch("setMessageDialog", "Baixando arquivo");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        })
        .catch(() => {
          // console.log(err);
          this.$store.dispatch("setMessageDialog", "Erro ao gerar relatório");
          setTimeout(() => {
            this.$store.dispatch("setShowDialog", false);
          }, 1000);
        });
    },
    showImage(item) {
      this.dialog = true;
      setTimeout(() => {
        this.$store.commit("setEvento", item);
      }, 100);
    },
    search(value) {
      this.$store.dispatch("setFilterReport", value || "");
    },
        nextEvent(actual) {
      let idx = this.$store.getters.getReportFilter.findIndex(
        (evt) => evt.id == actual
      );
      if (idx + 1 < this.$store.getters.getReportFilter.length) {
        this.$store.dispatch(
          "setEvento",
          this.$store.getters.getReportFilter[idx + 1]
        );
      }
    },
    previousEvent(actual) {
      let idx = this.$store.getters.getReportFilter.findIndex(
        (evt) => evt.id == actual
      );
      if (idx - 1 >= 0) {
        this.$store.dispatch(
          "setEvento",
          this.$store.getters.getReportFilter[idx - 1]
        );
      }
    },
  },
  computed: {
    getMonitoramentos: {
      get() {
        return this.$store.getters.getMonitoramentos;
      },
      set() {},
    },
    getCamerasSelect: {
      get() {
        return this.$store.getters.getCamerasSelect;
      },
      set() {},
    },
  },
  mounted() {
    this.get();
    this.relatorio.cameraId = this.$store.getters.getLastReport.cameraId;
    this.relatorio.start = this.$store.getters.getLastReport.start;
    this.relatorio.end = this.$store.getters.getLastReport.end;
    this.$store.dispatch("setShowDialog", false);
  },
  beforeMount() {
    this.relatorio.cameraId = this.$store.getters.getLastReport.cameraId;
    this.relatorio.start = this.$store.getters.getLastReport.start;
    this.relatorio.end = this.$store.getters.getLastReport.end;
  },
};
</script>

<style>
</style>