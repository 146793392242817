<template>
<div>
  <span v-if="!eventsCodes.includes(code)">
    {{ text }}
  </span>
  <v-tooltip top v-else>
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        {{
          text
        }}
      </span>
    </template>
    <v-card min-width="250">
      <v-card-title>
        <v-spacer></v-spacer>
        Dados do Evento
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <code >
          <pre>{{ stringfy(detections) }}</pre>
        </code>
      </v-card-text>
    </v-card>
  </v-tooltip>
</div>
</template>

<script>
export default {
  name: "SyncPayload",
  props: ["code", "detections", "text"],
  methods: {
    stringfy(json) {
      return JSON.stringify(json, null, 2);
    },
  },
  data: () => {
    return {
      eventsCodes: ['connected', 'error'],
    };
  },
};
</script>

<style></style>